.paymentMethodBoxWrapper {
  flex-grow: 0;
  flex-basis: 25%;
  min-height: 135px;
  margin-right: theme('space.5');
  border: 1px solid transparent;
  transition: all 250ms ease-in-out;

  @media (max-width: 1023px) {
    flex-basis: calc(50% - 6px);
    min-height: auto;
    margin: 0 theme('space.3') theme('space.3') 0;

    &:nth-child(even) {
      margin-right: 0;
    }
  }

  &:last-child {
    margin-right: 0;
  }

  &:hover,
  &.isChecked:not(&.isDisabled) {
    border-color: theme('colors.primary');

    .paymentMethodBox {
      border: 1px solid theme('colors.primary');
    }
  }

  &.isDisabled {
    background-color: theme('colors.gray50');
    color: theme('colors.gray400');
    filter: grayscale(100%);

    .paymentMethodBox {
      cursor: not-allowed;
    }

    &:hover {
      border-color: transparent;

      .paymentMethodBox {
        border-color: theme('colors.lightGrey');
      }
    }
  }
}

.paymentMethodBox {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: theme('space.9') theme('space.3');
  border: 1px solid theme('colors.lightGrey');
  transition: all 250ms ease-in-out;
  cursor: pointer;
}

.paymentMethodInfoWrap {
  position: absolute;
  top: 0;
  right: 0;
  padding: theme('space.1');
  background-color: theme('colors.primary');
  transition: all 250ms ease-in-out;

  &:hover {
    background-color: theme('colors.gray500');
  }
}

.paymentMethodInfoText {
  display: none;
  min-width: 150px;
  position: absolute;
  bottom: 100%;
  right: 50%;
  transform: translateX(50%);
  padding-bottom: theme('space.5');
  cursor: auto;

  @media (max-width: 1023px) {
    width: calc(50vw - 34px);
    right: -2px;
    transform: translateX(0);
  }

  &:before,
  &:after {
    content: '';
    position: absolute;
    left: 50%;
    bottom: 13px;
    width: 14px;
    height: 14px;
    margin-left: -7px;
    background-color: theme('colors.white');
    transform: rotate(45deg);
    z-index: 11;
  }

  &:after {
    box-shadow: 0 0 5px rgba(15, 32, 47, .5);
    z-index: 9;
  }

  &.showInfo {
    display: block;
  }
}

.paymentMethodInfoTextInner {
  position: relative;
  padding: theme('space.2');
  background-color: theme('colors.white');
  box-shadow: 0 0 5px rgba(15, 32, 47, .5);
  font-size: 12px;
  color: theme('colors.fontColor');
  z-index: 10;
}

.paymentMethodBoxInput {
  display: none;
}
