.wrapper {
  margin: theme('spacing.12') auto;
  max-width: 980px;

  @media (max-width: 1023px) {
    margin: theme('spacing.6') 0;
  }
}

.title {
  display: block;
  margin-bottom: theme('spacing.6');
  text-align: center;
  font-size: 25px;
  font-weight: 700;
  text-transform: uppercase;
}

.spinnerWrap {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(165, 172, 177, 0.5);
  z-index: 1003;
  display: flex;
  justify-content: center;
  align-items: center;
}

.labelHasNoMargin {
  margin: 0;

  & > * {
    margin: 0;
  }
}

.infoModalContent {
  & > * {
    color: theme('colors.fontColor');
    font-size: 14px;
    margin: inherit !important;
  }
}
